import { useState, useEffect } from "react";
import "../styles/Contact.css";

function Contact({ responsiveMenuVisible, setResponsiveMenuVisible }) {
  const [inputLastName, setInputLastName] = useState("");
  const [inputFirstName, setInputFirstName] = useState("");
  const [inputCompanyName, setInputCompanyName] = useState("");
  const [inputMailAddress, setInputMailAddress] = useState("");
  const [inputSubject, setInputSubject] = useState("");
  const [inputMessage, setInputMessage] = useState("");

  const [validMail, setValidMail] = useState("");
  const [sendMail, setSendMail] = useState(false);
  const [servMessage, setServMessage] = useState("");

  // var servMessage = "";

  let re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  function handleLastNameInput(e) {
    setInputLastName(e.target.value);
  }
  function handleFirstNameInput(e) {
    setInputFirstName(e.target.value);
  }
  function handleCompanyNameInput(e) {
    setInputCompanyName(e.target.value);
  }
  function handleMailAddressInput(e) {
    setInputMailAddress(e.target.value);
  }
  function handleSubjectInput(e) {
    setInputSubject(e.target.value);
  }
  function handleMessageInput(e) {
    setInputMessage(e.target.value);
  }

  // function handleBlur(e) {
  //   if (inputLastName === "") {
  //     document.getElementById(e.target.id).style = "border: 2px red solid";
  //   } else if (inputFirstName === "") {
  //     document.getElementById(e.target.id).style = "border: 2px red solid";
  //   }
  // }

  function handleSubmit(e) {
    e.preventDefault();
    //Créer autant de props que de champs du form. Ces props doivent se mettre à jour à chaque modification. Lors du submit on vérifie que tout va bien, puis soit on envoie un mail, soit on notifie que ça ne fonctionne pas.
    if (re.test(inputMailAddress)) {
      setValidMail(true);
    } else {
      setValidMail(false);
    }
    if (
      inputLastName !== "" &&
      inputFirstName !== "" &&
      inputCompanyName !== "" &&
      inputMailAddress !== "" &&
      inputSubject !== "" &&
      inputMessage !== "" &&
      !sendMail &&
      re.test(inputMailAddress)
    ) {
      setSendMail(true);
    } else {
      if (inputLastName === "") {
        document.getElementById("lastName").style = "border: 2px red solid";
      }
      if (inputFirstName === "") {
        document.getElementById("firstName").style = "border: 2px red solid";
      }
      if (inputCompanyName === "") {
        document.getElementById("company").style = "border: 2px red solid";
      }
      if (inputMailAddress === "") {
        document.getElementById("userMail").style = "border: 2px red solid";
      }
      if (!validMail) {
        document.getElementById("userMail").style = "border: 2px red solid";
      }
      if (inputSubject === "") {
        document.getElementById("object").style = "border: 2px red solid";
      }
      if (inputMessage === "") {
        document.getElementById("message").style = "border: 2px red solid";
      }
    }
  }

  useEffect(() => {
    if (inputLastName === "") {
      document.getElementById("lastName").style = "border: 2px grey solid";
    } else {
      document.getElementById("lastName").style = "border: 2px green solid";
    }
    if (inputFirstName === "") {
      document.getElementById("firstName").style = "border: 2px grey solid";
    } else {
      document.getElementById("firstName").style = "border: 2px green solid";
    }
    if (inputCompanyName === "") {
      document.getElementById("company").style = "border: 2px grey solid";
    } else {
      document.getElementById("company").style = "border: 2px green solid";
    }
    if (inputMailAddress === "") {
      document.getElementById("userMail").style = "border: 2px grey solid";
    } else {
      document.getElementById("userMail").style = "border: 2px green solid";
    }
    if (inputSubject === "") {
      document.getElementById("object").style = "border: 2px grey solid";
    } else {
      document.getElementById("object").style = "border: 2px green solid";
    }
    if (inputMessage === "") {
      document.getElementById("message").style = "border: 2px grey solid";
    } else {
      document.getElementById("message").style = "border: 2px green solid";
    }
  });

  useEffect(() => {
    if (sendMail) {
      const requestOptions = {
        method: "POST",
        // headers: "https://soleatech.fr",
        // origin: "https://soleatech.fr",
        body: JSON.stringify({
          lastName: inputLastName,
          firstName: inputFirstName,
          company: inputCompanyName,
          userMail: inputMailAddress,
          subject: inputSubject,
          message: inputMessage,
        }),
        // headers: {
        //   "Content-type": "application/json",
        // },
        // mode: "no-cors",
        mode: "cors",

        url: `https://soleatech.fr`,
      };
      fetch("https://api.soleatech.fr/mail", requestOptions)
        // .then((res) => (servMessage = { message: res.json() }))
        .then(
          function (result) {
            console.log(result); // inspect a result
            if (result.status === 200) {
              console.log(
                "Le mail a bien été délivré ! Pensez à vérifier vos spams."
              );
              setServMessage(
                "Le mail a bien été délivré ! Pensez à vérifier vos spams."
              );
            } else {
              setServMessage(
                "L'envoi de mail a rencontré un problème. Veuillez me contacter directement par mail à axel.soleatech@gmail.com"
              );
            }
          }
          // (res) => console.log(res)
          // res.status === 404
          //   ? ((servMessage = "Non envoyé"), console.log(res.status))
          //   : ((servMessage = "envoyé"), console.log(res.status))
        );

      document.title = "SoleaTech | Mail envoyé";
    }
  }, [sendMail]);

  return (
    <div
      className="contact-container"
      id="Contact"
      onClick={() => setResponsiveMenuVisible(false)}
    >
      <p className="filigrane">Contact</p>
      <h2 className="container-titre">Contact</h2>

      <div className="contactForm" id="contactForm">
        <p>
          Vous souhaitez en savoir davantage ou échanger à propos de votre
          projet ? Je vous invite à me contacter directement via le formulaire
          ci dessous.
        </p>
        <p>
          Vous pouvez également me contacter directement à l'adresse suivante{" "}
          <a
            href="mailto:axel.soleatech@gmail.com?subject=Prise de contact pour un futur projet"
            className="lien"
            title="Gmail"
          >
            axel.soleatech@gmail.com
          </a>
        </p>
        <form onSubmit={handleSubmit}>
          <p>
            <label htmlFor="lastName">Votre Nom : </label>
          </p>
          <input
            name="lastName"
            type="text"
            id="lastName"
            onChange={handleLastNameInput}
            value={inputLastName}
          />
          <br />
          <p>
            <label htmlFor="firstName">Votre Prénom : </label>
          </p>
          <input
            name="firstName"
            type="text"
            id="firstName"
            onChange={handleFirstNameInput}
            value={inputFirstName}
          />
          <br />
          <p>
            <label htmlFor="company">Société : </label>
          </p>
          <input
            name="company"
            type="text"
            id="company"
            onChange={handleCompanyNameInput}
            value={inputCompanyName}
          />
          <br />
          <p>
            <label htmlFor="userMail">Votre adresse mail : </label>
          </p>
          <input
            name="userMail"
            type="email"
            id="userMail"
            onChange={handleMailAddressInput}
            value={inputMailAddress}
          />
          {validMail !== "" ? (
            !validMail ? (
              <p style={{ color: "red" }}>
                Ceci n'est pas une adresse mail valide.
              </p>
            ) : null
          ) : null}
          <br />
          <p>
            <label htmlFor="object">L'objet de votre message : </label>
          </p>
          <input
            name="object"
            type="text"
            id="object"
            placeholder="[SoléaTech] - Prise de contact"
            onChange={handleSubjectInput}
            value={inputSubject}
          />
          <br />
          <p id="contactMessage">
            <label htmlFor="message">Votre message : </label>
          </p>
          <br />
          <textarea
            name="message"
            type="text"
            id="message"
            placeholder="Ecrivez votre message ici."
            onChange={handleMessageInput}
            value={inputMessage}
          ></textarea>{" "}
          <br />
          {sendMail ? <p>{servMessage}</p> : null}
          <p id="sendButton">
            <button type="submit">Envoyer</button>
          </p>
        </form>
      </div>
    </div>
  );
}

export default Contact;
