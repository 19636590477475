import backgroundHome from "../assets/backgroundHome.png";
import soleatechLogo from "../assets/logo-soleatech.png";
import "../styles/Home.css";

function Home({ responsiveMenuVisible, setResponsiveMenuVisible }) {
  return (
    <div
      className="container-Home"
      onClick={() => setResponsiveMenuVisible(false)}
    >
      <div className="background-div">
        <img
          className="background-img"
          src={backgroundHome}
          alt="background-Soleatech"
        />
      </div>
      <div className="mid-home-div-logo">
        <img src={soleatechLogo} alt="Logo-Soleatech" />
      </div>
      <div className="mid-home-div-text">
        <h1>SoleaTech</h1>
        <h2>WEB DEVELOPMENT FOR BUSINESS DEVELOPMENT</h2>
      </div>
      <div className="home-button">
        <a className="btn-get-started" href="#Contact">
          Un projet ? →
        </a>
      </div>
    </div>
  );
}

export default Home;
