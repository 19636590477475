import shapeWhite from "../assets/shape-white2grey.png";
import "../styles/ShapeWhite2Grey.css";

function ShapeWhite2Grey({ responsiveMenuVisible, setResponsiveMenuVisible }) {
  return (
    <div
      className="shape-white-div"
      onClick={() => setResponsiveMenuVisible(false)}
    >
      <img src={shapeWhite} alt="shape-white" />
    </div>
  );
}

export default ShapeWhite2Grey;
