import "../styles/Work.css";
import adiwattDesignApp from "../assets/adiwatt-design_app_design.jpg";
import adiwattDesignHome from "../assets/adiwatt-design_accueil.png";
import recomendationMaxence from "../assets/recommendation_AdiWatt_Maxence.png";
import erp from "../assets/ERP.png";

function Work({ responsiveMenuVisible, setResponsiveMenuVisible }) {
  return (
    <div
      className="work-container"
      id="Work"
      onClick={() => setResponsiveMenuVisible(false)}
    >
      <p className="filigrane">Work</p>
      <div className="work-block-impair">
        <div className="text-block">
          <div>
            <h3>Logiciel Online</h3>
          </div>
          <div>
            <p>
              Single Page Application de dimensionnement solaire photovoltaïque.
              Projet de développement FullStack réalisé de A à Z.
            </p>
          </div>
        </div>
        <div className="img-block">
          <a
            href="https://adiwatt-design.com"
            target="_blank"
            rel="noreferrer"
            title="adiwatt-design"
          >
            <img src={adiwattDesignApp} alt="adiwatt-design.com - App" />
          </a>
        </div>
      </div>
      <div className="work-block-pair">
        <div className="img-block">
          <a
            href="https://adiwatt-design.com"
            target="_blank"
            rel="noreferrer"
            title="adiwatt-design"
          >
            <img src={adiwattDesignHome} alt="adiwatt-design.com - Home" />
          </a>
        </div>
        <div className="text-block">
          <div>
            <h3>Web Design</h3>
          </div>
          <div>
            <p>
              Page d'accueil de la plateforme de dimensionnement solaire
              photovoltaïque adiwatt-design.com. Projet de développement
              FullStack réalisé de A à Z.
            </p>
          </div>
        </div>
      </div>
      <div className="work-block-impair">
        <div className="text-block">
          <div>
            <h3>Développement Web FullStack</h3>
          </div>
          <div>
            <p>Commentaire - malt.fr</p>
          </div>
        </div>
        <div className="img-block">
          <a
            href="https://malt.fr/profile/axelwadoux"
            target="_blank"
            rel="noreferrer"
            title="malt"
          >
            <img src={recomendationMaxence} alt="malt.fr - Recommendation" />
          </a>
        </div>
      </div>
      <div className="work-block-pair">
        <div className="img-block">
          <a
            href="https://malt.fr/profile/axelwadoux"
            target="_blank"
            rel="noreferrer"
            title="malt"
          >
            <img src={erp} alt="Outil de gestion" />
          </a>
        </div>
        <div className="text-block">
          <div>
            <h3>Outil de gestion</h3>
          </div>
          <div>
            <p>
              Outil de synchronisation des plannings inter-services. Ce
              développement a eu lieu pour une entreprise du secteur industriel.
              L'outil relie désormais les plannings des services Commercial,
              Chantier, Bureau d'études et Logistique.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Work;
