import AboutImg from "../assets/axel.png";
import Circle from "../assets/circle2.png";
import MaltIco from "../assets/malt-icone-50.png";
import shapeGrey from "../assets/shape-grey.png";

import "../styles/About.css";

function About({ responsiveMenuVisible, setResponsiveMenuVisible }) {
  return (
    <div
      className="about-container"
      id="About"
      onClick={() => setResponsiveMenuVisible(false)}
    >
      <div className="left-block">
        <h2>A propos</h2>
        <h3>Développeur web FullStack basé à Tours.</h3>
        <p>
          Je conçois principalement des sites internet et applications
          logicielles destinées aux professionnels.
        </p>
        <p>
          Chaque projet est réalisé sur-mesure avec l'objectif de répondre à vos
          besoins en créant l'outil le plus performant possible.
        </p>
        <div className="about-button">
          <a
            className="btn-get-about"
            href="https://malt.fr/profile/axelwadoux"
            target="_blank"
            rel="noreferrer"
            title="MALT"
          >
            <p>Pour en savoir plus</p> <p className="arrow-yellow">→</p>
          </a>
        </div>
      </div>
      <div className="right-block">
        <img src={AboutImg} alt="About" />
      </div>
      <div className="about-circle-div">
        <p>Average score on Google Page Speed Insights : 98/100</p>
        <img className="about-circle-img" src={Circle} alt="Circle" />
      </div>
      <p className="filigrane">Hello</p>
      {/* <div className="shape-grey-div">
        <img src={shapeGrey} alt="shape-grey" />
      </div> */}
    </div>
  );
}

export default About;
