import "../styles/Services.css";
import iconeDev from "../assets/icone-prestation-web-dev.png";
import iconeWebDesign from "../assets/icone-prestation-webDesign2.png";
import iconeSEO from "../assets/icone-prestation-seo2.png";
import iconeStrategie from "../assets/icone-prestation-strategie.png";

function Services({ responsiveMenuVisible, setResponsiveMenuVisible }) {
  return (
    <div
      className="services-container"
      id="Services"
      onClick={() => setResponsiveMenuVisible(false)}
    >
      {/* <div className="shape-white-div">
        <img src={shapeWhite} alt="shape-white" />
      </div> */}
      <p className="filigrane">Services</p>
      <div
        className="services-div"
        id="web-dev-div"
        onClick={() => setResponsiveMenuVisible(false)}
      >
        <img src={iconeDev} alt="icone-dev" />
        <h3>Développement Web</h3>
        <p>
          Pour obtenir un site web performant en accord avec vos attentes,
          chaque solution est d'abord conçue à vos côtés puis développée de
          manière claire et organisée. Que ce soit pour un simple site vitrine,
          comme pour un logiciel online les solutions développées sont d'emblées
          optimisées pour que vous et vos utilisateurs jouissiez des meilleures
          performances web.
        </p>
        <p>#JavaScript #PHP #ReactJs #NodeJs #HTML5 #CSS3 #Ajax #SQL</p>
      </div>
      <div
        className="services-div"
        id="webDesign"
        onClick={() => setResponsiveMenuVisible(false)}
      >
        <img src={iconeWebDesign} alt="icone-dev" />
        <h3>Web Design</h3>
        <p>
          Parce qu'un site internet ne séduit pas ses utilisateurs qu'avec ses
          performances de conception, je met l'UX (Expérience Utilisateur) et le
          Responsive Design au coeur de chacun de mes développements. Du simple
          site vitrine à la plateforme logicielle, chaque détail est pensé avec
          vous pour correspondre à votre image et faire de votre site web une
          référence !
        </p>
        <p>#ReactJs #Canva #Photoshop #HTML #CSS</p>
      </div>
      <div
        className="services-div"
        id="seo"
        onClick={() => setResponsiveMenuVisible(false)}
      >
        <img src={iconeSEO} alt="icone-dev" />
        <h3>SEO</h3>
        <p>
          Vous vous demandez si votre site apparaîtra dans les premiers
          résultats de nos moteurs de recherches préférés ? Lors de la
          conception de mes sites internet, le code ainsi que le texte est
          d'emblée orienté SEO pour positionner votre site rapidement en tête
          des résultats. En revanche si votre site internet est déjà existant,
          un audit sera nécessaire afin de cibler les points d'améliorations
          pour mettre en place les meilleures stratégies !
        </p>
        <p>#GooglePageSpeedInsights #Concurrence #MotsClefs #Contenu</p>
      </div>
      <div
        className="services-div"
        id="strategie"
        onClick={() => setResponsiveMenuVisible(false)}
      >
        <img src={iconeStrategie} alt="icone-dev" />
        <h3>Stratégie / Planning</h3>
        <p>
          Besoin de développer un outil pour mutualiser, centraliser et
          synchroniser les données qui circulent entre vos différents services ?
          Nous ciblons ensemble vos besoins et mettons en place un outil
          performant pour améliorer les flux d'informations dans votre
          entreprise. Vous et vos collaborateurs gagnez ainsi en efficacité,
          augmentant votre chiffre d'affaires !
        </p>
        <p>#ERP #AppsScript #Planning</p>
      </div>
    </div>
  );
}

export default Services;
