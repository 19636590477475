// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import EDLLogoFr from "../assets/EDL-logo.png";
import malt_ico from "../assets/malt-icone-50.png";
import linkedIn_ico from "../assets/linkedin_button.png";
import gmail_ico from "../assets/gmail-50.png";
import hamburgerIcon from "../assets/Icon_menu.png";

import "../styles/EDLBanner.css";
import { useState } from "react";

function EDLBanner({ responsiveMenuVisible, setResponsiveMenuVisible }) {
  // const [responsiveMenuVisible, setResponsiveMenuVisible] = useState(false);
  // var clickCount = 0;

  // function handleMenuClick() {
  //   setResponsiveMenuVisible(true);
  // }

  return (
    <div className="EDLbanner">
      {window.innerWidth > 900 ? setResponsiveMenuVisible(true) : null}
      <div className="EDLlogo-soleatech-fr-div">
        <a href="https://energiesdeloire.com/">
          <img src={EDLLogoFr} alt="Soleatech-logo" />
        </a>
      </div>
      <div className="EDLmenuNavAndContact">
        <div className="EDLmenuNav">
          {responsiveMenuVisible ? (
            <ul>
              <li>
                <a href="https://energiesdeloire.com/lequipe">Choisir EDL 👋</a>
              </li>
              <li>
                <a href="https://energiesdeloire.com/nos-realisations">
                  Nos réalisations
                </a>
              </li>
              <li>
                <a href="https://energiesdeloire.com/nos-solutions">
                  Nos solutions
                </a>
              </li>
              <li>
                <a href="https://energiesdeloire.com/passer-au-solaire">
                  Passer au solaire
                </a>
              </li>
              <li>
                <a href="https://energiesdeloire.com/blog">Blog</a>
              </li>
              <li>
                <a href="https://energiesdeloire.com/temoignages/">
                  Témoignages
                </a>
              </li>
              <li>
                <a href="https://energiesdeloire.com/contact/">Contact</a>
              </li>
              <li>
                <a href="https://energiesdeloire.com/carrieres/">Carrière</a>
              </li>
            </ul>
          ) : (
            <a
              className="hamburgerIcon"
              onClick={() => setResponsiveMenuVisible(true)}
            >
              <img src={hamburgerIcon} alt="menu_responsive" />
            </a>
          )}
        </div>
        <div className="EDLheader-link">
          <a
            href="https://www.linkedin.com/in/axel-wadoux-07a00760/"
            target="_blank"
            rel="noreferrer"
            title="LinkedIn"
          >
            <img src={linkedIn_ico} alt="LinkedIn" height="15" width="15" />
          </a>
          <a
            href="https://www.malt.fr/profile/axelwadoux"
            target="_blank"
            rel="noreferrer"
            title="MALT"
          >
            <img src={malt_ico} alt="Malt" height="15" width="15" />
          </a>
          <a
            href="mailto:axel.soleatech@gmail.com?subject=Prise de contact pour un futur projet"
            className="email"
            title="Gmail"
          >
            <img src={gmail_ico} alt="Gmail" height="15" width="15" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default EDLBanner;
