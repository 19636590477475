import backgroundEDLHome from "../assets/backgroundEDL.png";
import uploadImg from "../assets/upload.png";
import "../styles/ExtractTextFromPdf.css";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
const FormData = require("form-data");

function ExtractTextFromPdf({
  responsiveMenuVisible,
  setResponsiveMenuVisible,
}) {
  const [errorMessage, setErrorMessage] = useState("");
  const [onDropMsg, setOnDropMsg] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [rdyToSend, setRdyToSend] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [amountPb, setAmountPb] = useState(false);
  const [amountPbBorder, setAmountPbBorder] = useState("none");

  const [jsonResponse, setJsonResponse] = useState({
    message: "",
    pdfText: "",
    netAPayer: "",
    netAPayerInd: "",
    montantHT: "",
    montantHTInd: "",
    montantHTFwd: "",
    montantHTBwd: "",
    montantTTCFwd: "",
    montantTTCBwd: "",
    montantTVAFwd: "",
    montantTVABwd: "",
    nFacture: "",
    nFactureFwd: "",
    nFactureBwd: "",
    dateFacture: "",
    dateFactureFwd: "",
    dateFactureBwd: "",
    dateFactureInd: "",
  });

  const handleAmountPb = () => {
    if (
      jsonResponse.montantHT + jsonResponse.montantTVA !==
      jsonResponse.montantTTC
    ) {
      setAmountPb(true);
      setAmountPbBorder("2px solid red");
    }
  };

  const changeHandler = (event) => {
    console.log(event.target.files[0]);
    setSelectedFile(event.target.files[0]);

    setIsSelected(true);
    document.getElementById("btn-get-started").style = "z-index:100";
  };

  const handleRdyToSend = () => {
    console.log(selectedFile);
    let formData = new FormData();
    formData.append("fileToUpload", selectedFile);
    for (var key of formData.entries()) {
      console.log(key[0] + ", " + key[1]);
    }
    console.log(formData);
    setLoading(true);

    axios
      .post("https://test.soleatech.fr/gettextfrompdf", formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      })
      .catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message);
        }
        console.log(error.config);
        setLoading(false);
      })
      .then((response) => {
        console.log(response);
        console.log(response.data);
        var theRes = response.data;

        setJsonResponse((prevState) => {
          return {
            ...prevState,
            pdfText: theRes.pdfText,
            netAPayer: theRes.netAPayer,
            netAPayerInd: theRes.netAPayerInd,
            montantHT: theRes.montantHT,
            montantHTInd: theRes.montantHTInd,
            montantTTC: theRes.montantTTC,
            montantTTCInd: theRes.montantTTCInd,
            montantTVA: theRes.montantTVA,
            montantTVAInd: theRes.montantTVAInd,
            message: theRes.message,
            montantHTFwd: theRes.montantHTFwd,
            montantHTBwd: theRes.montantHTBwd,
            montantTTCFwd: theRes.montantTTCFwd,
            montantTTCBwd: theRes.montantTTCBwd,
            montantTVAFwd: theRes.montantTVAFwd,
            montantTVABwd: theRes.montantTVABwd,
            nFacture: theRes.nFacture,
            nFactureFwd: theRes.nFactureFwd,
            nFactureBwd: theRes.nFactureBwd,
            dateFacture: theRes.dateFacture,
            dateFactureFwd: theRes.dateFactureFwd,
            dateFactureBwd: theRes.dateFactureBwd,
            dateFactureInd: theRes.dateFactureInd,
          };
        });

        setIsLoaded(true);
        setLoading(false);
        handleAmountPb();

        console.log(jsonResponse);
        console.log(theRes);
      });

    setRdyToSend(true);
  };

  //\\\\\\\\\\\\\  Récupère les données depuis airtable.  //////////////////\\
  // const handleGetTable = () => {
  //   var config = {
  //     method: "get",
  //     url: "https://api.airtable.com/v0/appkjws54CFXqjH6K/Test1",
  //     headers: {
  //       Authorization: "Bearer MyToken",
  //       // Cookie: "brw=brwGLeq5VYvLX0DSt",
  //     },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       console.log(JSON.stringify(response.records));
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  const dragOver = (e) => {
    e.preventDefault();
    setOnDropMsg("Déposez le ici !");
    document.getElementById("dropZone").style.transform = "scale(1.05)";
  };

  const dragEnter = (e) => {
    e.preventDefault();
    setOnDropMsg("Déposez le ici !");
    document.getElementById("dropZone").style.transform = "scale(1.05)";
  };

  const dragLeave = (e) => {
    e.preventDefault();
    setOnDropMsg("");
    document.getElementById("dropZone").style.transform = "scale(1)";
  };

  const fileDrop = (e) => {
    e.preventDefault();
    console.log(e.dataTransfer.files[0]);
    var file = e.dataTransfer.files[0];
    // console.log(selectedFile);
    // if (files.length) {
    handleFiles(file);
    // }
    setOnDropMsg("");
  };

  const handleFiles = (file) => {
    if (validateFile(file)) {
      // add to an array so we can display the name of file
      setSelectedFile(file);
      setIsSelected(true);
      document.getElementById("btn-get-started").style = "z-index:100";
    } else {
      // add a new property called invalid
      // add to the same array so we can display the name of the file
      // set error message
      // file["invalid"] = true;
      // setSelectedFile(file);
      setIsSelected(false);
      setErrorMessage("File type not permitted");
    }
  };

  const validateFile = (file) => {
    const validTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/x-icon",
      "application/pdf",
    ];
    if (validTypes.includes(file.type)) {
      return true;
    }
    return false;
  };

  return (
    <div
      className="container-Home"
      onClick={() => setResponsiveMenuVisible(false)}
    >
      {loading ? <div id="spinner-modal"></div> : null}

      <div className="background-div">
        <img
          className="background-img"
          src={backgroundEDLHome}
          alt="background-Soleatech"
        />
      </div>
      <div className="blog-home-title">
        <p>Energies de loire</p>
        <h1>Déposer une facture</h1>
        <h2>Glissez-déposez votre facture, on s'occupe du reste !</h2>
        {!isLoaded ? (
          <div
            className="submitForm"
            id="submitForm"
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}
          >
            <div className="upload-icon"></div>
            <form encType="multipart/form-data">
              {/* <p>Déposer ici votre document PDF :</p> */}
              {/* <label for="btn-browse">Coucou</label>
              <input
                className="btn-browse"
                id="btn-browse"
                type="file"
                name="fileToUpload"
                onChange={changeHandler}
              /> */}
              1 - Glissez votre document ci-dessous ou cliquez sur{" "}
              <button
                className="btn-browse"
                onClick={(e) => {
                  e.preventDefault();
                  document.getElementById("getFile").click();
                }}
              >
                Parcourir...
              </button>
              <input
                type="file"
                id="getFile"
                onChange={changeHandler}
                name="fileToUpload"
              ></input>
              {/* {loading ? (
                <p>
                  Votre document est en cours de traitement. Merci de bien
                  vouloir patienter...
                </p>
              ) : null} */}
              {isSelected ? (
                <div>
                  {errorMessage !== "" ? <p>{errorMessage}</p> : null}
                  <p>Nom du fichier : {selectedFile.name}</p>
                  <p>Type de fichier : {selectedFile.type}</p>
                  <p>Poids (bytes) : {selectedFile.size}</p>
                </div>
              ) : (
                <div className="dropZone" id="dropZone">
                  {" "}
                  {onDropMsg !== "" ? (
                    <p>{onDropMsg}</p>
                  ) : (
                    <div>
                      <p>Glissez votre document ici !</p>
                    </div>
                  )}
                  <img src={uploadImg} alt="upload" />
                </div>
              )}
              2 -{" "}
              <input
                className="btn-get-started"
                id="btn-get-started"
                type="button"
                value="Envoyer"
                onClick={handleRdyToSend}
              />
              {/* <input
                type="button"
                value="Get Table Info"
                onClick={handleGetTable}
              /> */}
              {/* <input type="button" value="Envoyer" onClick={handleRdyToSend} /> */}
            </form>
          </div>
        ) : (
          <div className="submitForm">
            <h3>
              Merci de vérifier les champs ci-dessous avant d'envoyer votre
              facture.
            </h3>
            {amountPb ? (
              <p className="formAlert">
                Notre algorithme a détecté un potentiel problème concernant les
                montants relevés sur le document. Merci de bien prendre le temps
                de vérifier ces lignes.
              </p>
            ) : null}
            {jsonResponse.nFacture !== "" ? (
              <label>
                Numéro de facture :{" "}
                <select
                  type="text"
                  defaultValue={jsonResponse.nFacture}
                  onChange={(e) => {
                    setJsonResponse((prevState) => {
                      return {
                        ...prevState,
                        nFacture: e.target.value,
                      };
                    });
                  }}
                >
                  {" "}
                  <option value="-">Sélectionner</option>
                  <option value="nFactureFwd">
                    {jsonResponse.nFactureFwd}
                  </option>
                  <option value="nFactureFwd">
                    {jsonResponse.nFactureBwd}
                  </option>
                </select>
              </label>
            ) : null}
            {jsonResponse.dateFacture !== "" ? (
              <label>
                Date de la facture :
                {/* <input
                  type="text"
                  defaultValue={new Date(
                    jsonResponse.dateFacture
                  ).toLocaleDateString()}
                  onChange={(e) => {
                    setJsonResponse((prevState) => {
                      return {
                        ...prevState,
                        dateFacture: e.target.value,
                      };
                    });
                  }}
                ></input> */}
                <input
                  id="submitFormDateInput"
                  type="date"
                  defaultValue={
                    jsonResponse.dateFacture !== "Not found"
                      ? jsonResponse.dateFacture instanceof Date &&
                        !isNaN(jsonResponse.dateFacture.valueOf())
                        ? jsonResponse.dateFacture
                        : new Date(
                            jsonResponse.dateFacture -
                              new Date().getTimezoneOffset() * 120000
                          )
                            .toISOString()
                            .substring(0, 10)
                      : null
                  }
                  onChange={(e) => {
                    setJsonResponse((prevState) => {
                      return {
                        ...prevState,
                        dateFacture: new Date(e.target.value),
                      };
                    });
                  }}
                ></input>
              </label>
            ) : null}
            {jsonResponse.netAPayer !== "" ? (
              <label>
                Net à payer (€) :
                <input
                  type="text"
                  defaultValue={jsonResponse.netAPayer}
                  onChange={(e) => {
                    setJsonResponse((prevState) => {
                      return {
                        ...prevState,
                        netAPayer: e.target.value,
                      };
                    });
                  }}
                ></input>
              </label>
            ) : null}
            {jsonResponse.montantHT !== "" ? (
              <label>
                Montant H.T. (€) :{" "}
                <input
                  type="text"
                  defaultValue={jsonResponse.montantHT}
                  style={{ border: amountPbBorder }}
                  onChange={(e) => {
                    setJsonResponse((prevState) => {
                      return {
                        ...prevState,
                        montantHT: e.target.value,
                      };
                    });
                    handleAmountPb();
                  }}
                ></input>
              </label>
            ) : null}

            {jsonResponse.montantTVA !== "" ? (
              <label>
                TVA (€) :{" "}
                <input
                  type="text"
                  defaultValue={jsonResponse.montantTVA}
                  style={{ border: amountPbBorder }}
                  onChange={(e) => {
                    setJsonResponse((prevState) => {
                      return {
                        ...prevState,
                        montantTVA: e.target.value,
                      };
                    });
                    handleAmountPb();
                  }}
                ></input>
              </label>
            ) : null}
            {jsonResponse.montantTTC !== "" ? (
              <label>
                Montant T.T.C.(€) :{" "}
                <input
                  type="text"
                  defaultValue={jsonResponse.montantTTC}
                  style={{ border: amountPbBorder }}
                  onChange={(e) => {
                    setJsonResponse((prevState) => {
                      return {
                        ...prevState,
                        montantTTC: e.target.value,
                      };
                    });
                    handleAmountPb();
                  }}
                ></input>
              </label>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}

export default ExtractTextFromPdf;
