// import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";

import { useState } from "react";
import "../styles/App.css";
import Home from "./Home";
import Banner from "./Banner";
import About from "./About";
import Services from "./Services";
import ShapeWhite2Grey from "./ShapeWhite2Grey";
import ShapeGrey2White from "./ShapeGrey2White";
import Work from "./Work";
import Contact from "./Contact";
import Footer from "./Footer";
import MentionsLegales from "./MentionsLegales";
import SectionUnderConstruction from "./SectionUnderConstruction";
import BlogHome from "./BlogHome";
import BlogBanner from "./BlogBanner";
import ExtractTextFromPdf from "./ExtractTextFromPdf";
import EDLBanner from "./EDLBanner";

function App() {
  const [responsiveMenuVisible, setResponsiveMenuVisible] = useState(false);

  // function handleOutMenuClick() {
  //   setResponsiveMenuVisible(false);
  // }

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route
            path="/mentions_legales"
            element={
              <div>
                <Banner
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                />{" "}
                <MentionsLegales
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                />
              </div>
            }
          />
          {/* <Route
            path="/blog"
            element={
              <div>
                {" "}
                <BlogBanner
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                />
                <SectionUnderConstruction
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                />
                <Contact
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                />
              </div>
            }
          /> */}
          <Route
            path="/extracttextfrompdf"
            element={
              <div>
                {" "}
                <EDLBanner
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                />
                <ExtractTextFromPdf
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                />
              </div>
            }
          />
          <Route
            path="/"
            element={
              <div>
                <Banner
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                />
                <Home
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                />
                <About
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                />
                <ShapeWhite2Grey
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                />
                <Services
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                />
                <ShapeGrey2White
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                />
                <Work
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                />
                <Contact
                  responsiveMenuVisible={responsiveMenuVisible}
                  setResponsiveMenuVisible={setResponsiveMenuVisible}
                />
              </div>
            }
          />
        </Routes>
        <Footer
          responsiveMenuVisible={responsiveMenuVisible}
          setResponsiveMenuVisible={setResponsiveMenuVisible}
        />
      </div>
    </BrowserRouter>
  );
}

export default App;
