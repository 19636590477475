import { Link } from "react-router-dom";
import "../styles/Footer.css";
import malt_ico from "../assets/malt-icone-50.png";
import linkedIn_ico from "../assets/linkedin_button.png";
import gmail_ico from "../assets/gmail-50.png";

function Footer({ responsiveMenuVisible, setResponsiveMenuVisible }) {
  return (
    <div onClick={() => setResponsiveMenuVisible(false)}>
      <footer>
        <div className="footer">
          <p>
            2021 - Axel Wadoux - Tout droits réservés -{" "}
            <Link to="/mentions_legales" className="lien">
              Mentions légales
            </Link>
          </p>
          <div className="footer-link">
            <a
              href="https://www.linkedin.com/in/axel-wadoux-07a00760/"
              target="_blank"
              rel="noreferrer"
              title="LinkedIn"
            >
              <img src={linkedIn_ico} alt="LinkedIn" height={15} width={15} />
            </a>
            <a
              href="https://www.malt.fr/profile/axelwadoux"
              target="_blank"
              rel="noreferrer"
              title="MALT"
            >
              <img src={malt_ico} alt="Malt" height={15} width={15} />
            </a>
            <a
              href="mailto:axel.soleatech@gmail.com?subject=Prise de contact pour un futur projet"
              className="email"
              title="Gmail"
            >
              <img src={gmail_ico} alt="Gmail" height={15} width={15} />
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
