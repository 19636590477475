// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import soleatechLogoFr from "../assets/logo-soleatech-fr.png";
import malt_ico from "../assets/malt-icone-50.png";
import linkedIn_ico from "../assets/linkedin_button.png";
import gmail_ico from "../assets/gmail-50.png";
import hamburgerIcon from "../assets/Icon_menu.png";

import "../styles/Banner.css";
import { useState } from "react";

function Banner({ responsiveMenuVisible, setResponsiveMenuVisible }) {
  // const [responsiveMenuVisible, setResponsiveMenuVisible] = useState(false);
  // var clickCount = 0;

  // function handleMenuClick() {
  //   setResponsiveMenuVisible(true);
  // }

  return (
    <div className="banner">
      {window.innerWidth > 900 ? setResponsiveMenuVisible(true) : null}
      <div className="logo-soleatech-fr-div">
        <a href="https://www.soleatech.fr/">
          <img src={soleatechLogoFr} alt="Soleatech-logo" />
        </a>
      </div>
      <div className="menuNavAndContact">
        <div className="menuNav">
          {responsiveMenuVisible ? (
            <ul>
              <li>
                <Link to="/#">Accueil</Link>
              </li>
              <li>
                <Link to="/#About">Hello 👋</Link>
              </li>
              <li>
                <Link to="/#Services">Services</Link>
              </li>
              <li>
                <Link to="/#Work">Work</Link>
              </li>
              {/* <li>
                <Link to="/blog/#">Blog</Link>
              </li> */}
              <li>
                <Link to="/#Contact">Contact</Link>
              </li>
            </ul>
          ) : (
            <a
              className="hamburgerIcon"
              onClick={() => setResponsiveMenuVisible(true)}
            >
              <img src={hamburgerIcon} alt="menu_responsive" />
            </a>
          )}
          {/* <a className="hamburgerIcon" onClick={handleMenuClick}>
            <img src={hamburgerIcon} alt="menu_responsive" />
          </a>
          <ul>
            <li>
              <Link to="/#">Home</Link>
            </li>
            <li>
              <Link to="/#About">About</Link>
            </li>
            <li>
              <Link to="/#Services">Services</Link>
            </li>
            <li>
              <Link to="/#Work">Work</Link>
            </li>
            <li>
              <Link to="/#Contact">Contact</Link>
            </li>
          </ul> */}
        </div>
        <div className="header-link">
          <a
            href="https://www.linkedin.com/in/axel-wadoux-07a00760/"
            target="_blank"
            rel="noreferrer"
            title="LinkedIn"
          >
            <img src={linkedIn_ico} alt="LinkedIn" height="15" width="15" />
          </a>
          <a
            href="https://www.malt.fr/profile/axelwadoux"
            target="_blank"
            rel="noreferrer"
            title="MALT"
          >
            <img src={malt_ico} alt="Malt" height="15" width="15" />
          </a>
          <a
            href="mailto:axel.soleatech@gmail.com?subject=Prise de contact pour un futur projet"
            className="email"
            title="Gmail"
          >
            <img src={gmail_ico} alt="Gmail" height="15" width="15" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Banner;
